.entrar {
  background-color: #56b98a !important;
  margin: auto !important;
  transition: all 0.5s;
  border: 1px solid transparent;
  display: block;
}
.entrar:hover {
  border: 1px solid #56b98a;
}
.imagemFundo {
  border-radius: 10%;
  display: block;
  margin: auto;
  transition: all 0.5s;
  cursor: pointer;
}
.imagemFundo:hover {
  -webkit-filter: drop-shadow(15px 10px 5px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(15px 10px 5px rgba(0, 0, 0, 0.5));
}

.logoLogin {
  height: 25%;
  display: block;
  margin: auto;
  transition: all 0.5s;
  cursor: pointer;
}
.loading {
  display: block;
  text-align: center;
}


.botoes {
  display: block;
  margin: auto;
  transition: all 0.5s;
  cursor: pointer;
  margin-top: 2%;
}
.botoes:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}
